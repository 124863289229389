<template>
	<div class="order">
		<div class="order__bgs">
			<img class="order__bg1" :src="require('@img/calc/calc_confirm_bg1.webp')" alt="">
			<img class="order__bg2" :src="require('@img/calc/calc_confirm_bg2.webp')" alt="">
		</div>
		<div class="order__wrap">
			<div class="order__block">
				<div class="bg bg--left">
					<img :src="require('@img/calc/left_normal.webp')" alt="">
				</div>

				<div class="order__cnt">
					<template v-if="leftModuleLayout === 'counter-module'">
						<div class="order__title">{{translation.block_one_title[selectedLang]}}</div>
						<div class="order__counter">
							<div class="order__counter-num">{{order.params.count.value}}</div>
							<div class="order__counter-label">{{translation.counter_title[selectedLang]}}</div>
						</div>
					</template>

					<template v-if="leftModuleLayout === 'range-info-module'">
						<div class="order__title">{{translation.block_one_title[selectedLang]}}</div>
						<div class="order__img">
							<img v-if="currentImg" :src="currentImg" alt="">
							<img v-else class="default" :src="order.game.icon" alt="">
						</div>
						<div class="order__label">{{order.params.current.value}} {{translation.point_title[selectedLang]}}</div>
					</template>

					<template v-if="leftModuleLayout === 'current-select-module'">
						<div class="order__title">{{translation.block_one_title[selectedLang]}}</div>
						<div class="order__img">
							<img v-if="currentImg" :src="currentImg" alt="">
							<img v-else class="default" :src="order.game.icon" alt="">
						</div>
						<div class="order__label" v-if="currentLvl && currentLvl.value">
							{{currentLvl.value}}
							<template v-if="currentDivision">{{currentDivision.value}}</template>
						</div>
					</template>
				</div>
			</div>


			<div class="order__info">
				<div class="bg bg--centerTop">
					<img :src="require('@img/calc/center_top_normal.webp')" alt="">
				</div>
				<div class="bg bg--centerBot">
					<img :src="require('@img/calc/center_bot.webp')" alt="">
				</div>

				<div class="logo">
					<div class="logo__img" v-if="order.game">
						<img :src="order.game.icon" alt="">
					</div>
					<div class="logo__bg">
						<img :src="require('@img/pageGame/logo_bg.webp')" alt="">
					</div>

					<img class="logo__feather1" :src="require('@img/pageGame/feather1.webp')" alt="">
					<img class="logo__feather2" :src="require('@img/pageGame/feather2.webp')" alt="">
					<img class="logo__feather3" :src="require('@img/pageGame/feather3.webp')" alt="">
				</div>

				<div class="order__container">
					<div class="order__service">{{order.service.translations.title[selectedLang]}}</div>
					<div class="order__status">
						<div :class="`status status--${order.status}`" :style="{'background-image': `url(${require('@img/pattern1_alt.png')})`}">
							{{order.status}}
						</div>
					</div>
					<div class="order__section" v-if="order.champion">
						<div class="order__line">
							<div class="order__title">{{order.champion.name}}</div>
							<div class="order__champion">
								<img :src="order.champion.image" alt="">
							</div>
						</div>
					</div>

					<div class="order__section" v-if="order.service_params && order.service_params.length || order.service_order_params && order.service_order_params.length">
						<div class="order__line" v-for="option in order.service_params">
							+{{option.param.title}}: {{option.title}}
						</div>

						<div class="order__line" v-for="option in order.service_order_params">
							+ {{option.title}}
						</div>

						<div class="order__line" v-if="order.region">
							+ Region: {{order.region.title[selectedLang]}}
						</div>
					</div>

					<!--<div class="order__section" v-if="discountPercent">-->
					<!--&lt;!&ndash;<div class="order__title">{{$t('order.discount.title')}}</div>&ndash;&gt;-->
					<!--<div class="order__item">{{$t('calc.promo.text')}} <span> {{discountPercent}} % ({{result.discountPrice | numberNormalize}} $)</span></div>-->
					<!--</div>-->


					<div class="order__section">
						<div class="order__sub">REWARD:</div>
						<div class="order__prize" v-if="orderCoins">
							<div class="prize has-sm prize--coin">
								<div class="prize__icon prize__icon--sm">
									<icon-coin></icon-coin>
								</div>
								<span>+{{orderCoins.value}} DC Cashback</span>
							</div>
						</div>
						<div class="order__prize" v-if="orderEx">
							<div class="prize has-sm prize--ex">
								<div class="prize__icon">
									<icon-ex></icon-ex>
								</div>
								<span>+{{orderEx.value}} EXP</span>
							</div>
						</div>
					</div>

					<div class="order__section">
						<div class="order__sub">ORDER PRICE:</div>

						<div class="calc__price">
							<div class="calc__price-item">
								<div class="calc__total-old" v-if="order.discount_price">
									<span>{{+priceOrigin | numberPrice}}</span>
									<info-modal class="calc__info _m-0" :text="`The price, does not include your discount + promocode discount: ${order.discount_price}$`"></info-modal>
								</div>
								<div class="calc__total-new has-total">
									<span>{{+order.price | numberPrice}}</span> $
								</div>
							</div>
						</div>
					</div>

					<div class="order__section">
						<div class="date">
							<div class="date__title">Order completion date till:</div>
							<div class="date__cnt">
								<div class="date__val">{{order.deadline_at | dateNormalize}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="order__block" :class="{'has-logo': rightModuleLayout === 'dummy-module'}">
				<div class="bg bg--right">
					<img :src="require('@img/calc/right_normal.webp')" alt="">
				</div>

				<div class="order__cnt order__cnt--right">
					<template v-if="rightModuleLayout === 'counter-module'">
						<div class="order__title">{{translation.block_two_title[selectedLang]}}</div>
						<div class="order__counter">
							<div class="order__counter-num">{{order.params.count.value}}</div>
							<div class="order__counter-label">{{translation.counter_title[selectedLang]}}</div>
						</div>
					</template>

					<template v-if="rightModuleLayout === 'dummy-module'">
						<div class="order__dummy">
							<div class="order__img">
								<img :src="order.game.icon" alt="">
							</div>
						</div>
					</template>

					<template v-if="rightModuleLayout === 'range-info-module'">
						<div class="order__title">{{translation.block_two_title[selectedLang]}}</div>
						<div class="order__img">
							<img v-if="desiredImg" :src="desiredImg" alt="">
							<img v-else class="default" :src="order.game.icon" alt="">
						</div>
						<div class="order__label">{{order.params.desired.value}} {{translation.point_title[selectedLang]}}</div>
					</template>

					<template v-if="rightModuleLayout === 'desire-select-module'">
						<div class="order__title" v-if="translation.block_two_title">{{translation.block_two_title[selectedLang]}}</div>
						<div class="order__img">
							<img v-if="desiredImg" :src="desiredImg" alt="">
							<img v-else class="default" :src="order.game.icon" alt="">
						</div>
						<div class="order__label" v-if="desiredLvl && desiredLvl.value">
							{{desiredLvl.value}}
							<template v-if="desiredDivision">{{desiredDivision.value}}</template>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

	import InfoModal from '@components/InfoModal';
	import IconInfo from '@common/icons/IconInfo';
	import IconCoin from '@common/icons/IconCoin';
	import IconEx from '@common/icons/IconEx';

	export default {
		name: 'calc-layout',
		components:{
			InfoModal,
			IconInfo,
			IconCoin,
			IconEx
		},
		props: ['order', 'orderPrizes'],
		data() {
			return {
				ALT_CURRENCY_CODE: 'EUR',
			}
		},

		computed:{
			...mapState({
				PRIZE_TYPE_COINS: state => state.PRIZE_TYPE_COINS,
				PRIZE_TYPE_EX: state => state.PRIZE_TYPE_EX,
				settings: state => state.settings,
				selectedLang:state=> state.selectedLang,
				currencies:state=> state.currencies,
			}),

			orderCoins(){
				return $fn.find(this.orderPrizes, ['type', this.PRIZE_TYPE_COINS])
			},

			orderEx(){
				return $fn.find(this.orderPrizes, ['type', this.PRIZE_TYPE_EX])
			},

			currentLvl(){
				return this.order.params.current_level || this.order.params.level || this.order.params.current;
			},
			currentDivision(){
				return this.order.params.current_division || this.order.params.division;
			},

			currentImg(){
				let payload = this.currentDivision || this.currentLvl;
				if(!payload) return;

				let imgCnf = $fn.find(payload.additional, ['attribute', 'image']);
				return imgCnf ? imgCnf.value : null;
			},

			desiredLvl(){
				return this.order.params.desired_level || this.order.params.desired;
			},
			desiredDivision(){
				return this.order.params.desired_division;
			},

			desiredImg(){
				let payload = this.desiredDivision || this.desiredLvl || this.desired;
				if(!payload) return;

				let imgCnf = $fn.find(payload.additional, ['attribute', 'image']);
				return imgCnf ? imgCnf.value : null;
			},

			options(){
				return this.service.details.select.options;
			},
			champions(){
				return 	this.service.details.champions;
			},
			orderParams(){
				return this.service.details.orderParams;
			},
			serviceParams(){
				return this.service.details.params;
			},
			regions(){
				return 	this.service.details.regions;
			},
			translation () {
				return this.order.service.translations
			},

			discountPercent(){
				return this.result.discount * 100;
			},

			altCurrencyCnf(){
				return $fn.find(this.currencies, ['code', this.ALT_CURRENCY_CODE])
			},

			amountAlternative () {
				if (!this.altCurrencyCnf) return 0;
				return +(this.order.price * +this.altCurrencyCnf.rate).toFixed(2);
			},

			totalCoins(){
				return this.order.price * +this.settings.dark_coins_rate
			},

			priceOrigin(){
				return +this.order.price + +this.order.discount_price;
			},


			leftModuleLayout(){
				if(!this.order.service) return null;

				switch(this.order.service.layout_key){
					case 'slider':
						return 'counter-module';

					case 'range':
						return 'range-info-module';

					default:
						return 'current-select-module';
				}
			},

			rightModuleLayout(){
				if(!this.order.service) return null;

				switch(this.order.service.layout_key){
					case 'lvl_slider':
					case 'lvl_dv_slider':
						return 'counter-module';

					case 'slider':
					case 'lvl_single':
						return 'dummy-module';

					case 'range':
						return 'range-info-module';

					default:
						return 'desire-select-module';
				}
			},

			orderDeadline(){
				let date = new Date(this.order.deadline_at);
				return date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();
			},

			championImg(){
				if (!this.order.champion) return null;
				return 	$fn.find(this.champions, ['key', this.result.order.key]).image
			}
		},

		created(){
			// this.getOrderProfit()
		},

		methods:{
			...mapActions({
				GET_ORDER_PROFIT: 'orders/GET_ORDER_PROFIT'
			}),

			getOrderProfit(){
				this.GET_ORDER_PROFIT(this.order.id).then(resp => {
					console.log(resp);
				})
			}
		}
	};
</script>

<style scoped lang="scss">
	.inf{
		position: relative;
		&__head{
			padding: 3px;
			height: 23px;
			width: 22px;
			cursor: pointer;
			&:hover{
				+ .inf__cnt{
					opacity: 1;
					bottom: 30px;
					visibility: visible;
				}
				svg{
					fill: $c_alt;
				}
			}
		}
		&__cnt{
			position: absolute;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
			right: -20px;
			bottom: 40px;
			width: 240px;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			background: $c_main;
			border-radius: 8px;
			font-family: $f_alt;
			line-height: 1.3;
			box-shadow: 0 0 8px rgba(0,0,0,0.3);
			text-transform: none;
			@media(max-width: $md){
				font-size: 13px;
			}
			&:before {
				width: 14px;
				height: 14px;
				content: '';
				background: $c_main;
				transform: rotate(45deg);
				position: absolute;
				right: 24px;
				bottom: -4px;
			}
		}
	}

	.order{
		padding: 150px 0 1px;
		min-height: 630px;
		position: relative;
		@media(max-width: $md){
			padding: 30px 0 1px;
		}
		&__bgs{
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			max-width: 1200px;
			width: 100%;
			margin: 0 auto;
		}
		&__bg1{
			position: absolute;
			bottom: -50px;
			left: 0;
			@media(min-width: $mg){
				left: -70px;
			}
		}
		&__bg2{
			position: absolute;
			bottom: -130px;
			right: 130px;
			@media(min-width: $mg){
				right: -40px;
			}
		}
		&__status{
			display: flex;
			justify-content: center;
		}
		&__cnt{
			position: relative;
			max-width: 240px;
			width: 100%;
			margin: 0 30px 0 auto;
			@media(max-width: $def){
				z-index: 2;
			}
			@media(max-width: $md){
				margin: 0 auto;
			}
			&--right{
				margin: 0 auto 0 30px;
				@media(max-width: $md){
					margin: 0 auto;
				}
			}
		}
		&__bg{
			width: 72px;
			height: 303px;
			background-repeat: no-repeat;
			background-position: 0 0;
			position: absolute;
			top: 25px;
			right: 50px;
			@media(max-width: $def){
				right: 5px;
			}
			@media(max-width: $md){
				display: none;
			}
		}
		&__grid{
			display: flex;
			flex-wrap: wrap;
			padding: 20px 0 10px;
			margin-bottom: 30px;
			border-bottom: 1px solid $c_border;
		}
		&__left{
			width: 70%;
			padding-right: 160px;
			position: relative;
			@media(max-width: $def){
				padding-right: 60px;
			}
			@media(max-width: $md){
				width: 100%;
				padding-right: 0;
			}
			@media(max-width: $sm){
				padding-right: 0;
			}
		}
		&__right{
			width: 30%;
			@media(max-width: $md){
				width: 100%;
			}
		}
		&__service{
			text-align: center;
			font-family: $f_alt;
			color: $c_alt;
			font-size: 28px;
		}
		&__account{
			overflow: hidden;
		}
		&__acc{
			display: flex;
			flex-wrap: wrap;
			margin-left: -10px;
			margin-right: -10px;
			&-column{
				width: 50%;
				padding: 0 10px;
				@media(max-width: $md){
					width: 100%;
				}
			}
		}
		&__mainBtn{
			display: none;
			@media(max-width: $md){
				display: none;
			}
		}
		&__mobBtn{
			display: flex;
			justify-content: center;
			/*display: none;*/
			//@media(max-width: $md){
			//display: flex;
			//}
		}
		&__wrap{
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 35px;
			@media(max-width: $md){
				margin-bottom: 0;
			}
			.calc__title{
				margin-bottom: 5px;
			}
		}
		&__block{
			width: 33.333%;
			min-height: 240px;
			border-radius: 10px;
			padding: 10px;
			position: relative;
			@media(max-width: $md){
				width: 50%;
				margin-bottom: 130px;
			}
		}
		&__info{
			width: 33.333%;
			padding: 0 15px;
			position: relative;
			z-index: 1;
			@media(max-width: $md){
				order: 1;
				width: 100%;
				margin-bottom: 90px;
			}
		}
		&__title{
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 18px;
			line-height: 18px;
			position: relative;
			z-index: 1;
			height: 2.4em;
			overflow: hidden;
			border-bottom: 1px solid $c_border;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			@media(max-width: $md){
				font-size: 14px;
				line-height: 14px;
			}
		}
		&__img{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 150px;
			width: 100%;
			margin-bottom: 15px;
			img{
				max-height: 100%;
				&.default{
					opacity: 0.5;
				}
			}
		}
		&__label{
			color: $c_alt;
			text-transform: uppercase;
			font-size: 18px;
			text-align: center;
			font-weight: bold;
			background: rgba(255,255,255,0.4);
			max-width: 80%;
			margin: 0 auto;
		}
		&__option{
			margin-bottom: 15px;
		}
		&__container{
			position: relative;
			max-width: 310px;
			width: 100%;
			margin: 0 auto;
		}
		&__section{
			padding: 10px 0;
			border-bottom: 1px solid $c_border;
			font-size: 18px;
			&:last-child{
				border-bottom: none;
			}
			.order__title{
				padding: 0 10px;
			}
		}
		&__line{
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $f_alt;
		}

		&__sub{
			font-size: 18px;
			font-weight: bold;
			padding: 0 10px;
			text-transform: uppercase;
			text-align: center;
		}
		&__element{
			margin-bottom: 10px;
		}
		&__item{
			border-bottom: 1px dotted $c_border;
			margin-bottom: 2px;
			padding-bottom: 2px;
			text-align: center;
			font-size: 12px;
			text-transform: uppercase;
			span{
				color: $c_alt;
				font-weight: bold;
			}
			&:last-child{
				border-bottom: none;
			}
			&.has-active{
				color: $c_alt;
			}
		}
		&__prize{
			margin: 0 auto 5px;
			max-width: 150px;
			width: 100%;
			font-family: $f_alt;
		}
		&__counter{
			display: flex;
			width: 100%;
			flex-direction: column;
			align-items: center;
			&-num{
				width: 100%;
				text-align: center;
				font-size: 100px;
				height: 160px;
				border: none;
				pointer-events: none;
				line-height: 100px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-family: $f_alt;
				@media(max-width: $sm){
					height: 90px;
				}
			}
			&-label{
				text-align: center;
				text-transform: uppercase;
				font-size: 18px;
				color: $c_alt;
				font-weight: bold;
				display: flex;
				align-items: center;
			}
		}
		&__champion{
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				max-width: 50px;
			}
		}

		&__enter{
			display: flex;
			align-items: center;
		}
		&__or{
			padding: 0 10px;
			font-size: 14px;
			font-family: $f_alt;
		}
		&__btn{
			height: 30px;
			padding: 2px 10px 0;
			border-radius: 5px;
			cursor: pointer;
			background: #fff;
			font-family: $f_alt;
			text-transform: uppercase;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			position: relative;
			overflow: hidden;
			border: 1px solid #333;
			-webkit-transition: all 0.3s;
			transition: all 0.3s;
			box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
			user-select: none;
		}
		&__info{
			min-height: 400px;
			display: flex;
		}
		&__infoWrap{
			width: 100%;
		}
		&__dummy{
			min-height: 250px;
			display: flex;
			align-items: center;
			.order__img{
				opacity: 0.6;
			}
		}
	}

	.date {
		text-align: center;
		font-size: 16px;
		padding-top: 15px;
		position: relative;
		&__cnt{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.inf{
			top: -5px;
		}
		&__cnt{
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__title {
			margin-right: 5px;
			font-size: 16px;
			width: 100%;
			margin-bottom: 5px;
			span {
				font-weight: bold;
				color: $c_alt;
			}
		}
		&__val {
			font-family: $f_alt;
		}
	}

	.calc{
		&__price{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 10px;
			&-item{
				width: 45%;
				text-align: center;
			}
			&-dec{
				width: 10%;
			}
		}

		&__total{
			display: flex;
			justify-content: center;
			align-items: center;
			&-old{
				color: $c_text;
				font-size: 13px;
				line-height: 1.1;
				span{
					position: relative;
					padding: 0 5px;
					color: $c_text_light;
					font-weight: bold;
					font-size: 14px;
					&:before{
						content: '';
						display: block;
						position: absolute;
						left: 0;
						top: 50%;
						height: 1px;
						width: 100%;
						background: $c_text_light;
					}
				}
			}
			&-new{
				line-height: 1.1;
				font-size: 19px;
				font-weight: bold;
				white-space: nowrap;
				&.has-total{
					color: $c_alt;
					font-family: $f_alt;
					font-weight: normal;
					font-size: 30px;
				}
			}
			&-or{
				font-family: $f_alt;
				font-size: 17px;
				padding: 0 20px;
			}
		}
		&__priceEl{
			display: flex;
			justify-content: center;
			margin-bottom: 5px;
			&:last-child{
				margin-bottom: 0;
			}
			.calc__total-new{
				display: flex;
				align-items: center;
				font-size: 18px;
				span{
					margin-right: 5px;
				}
				svg{
					width: 21px;
					height: 21px;
					transform: translateY(-2px);
				}
			}
		}
	}

	.bg{
		position: absolute;
		&--centerTop{
			width: 546px;
			text-align: center;
			top: -170px;
			left: 50%;
			transform: translateX(-50%);
		}
		&--centerBot{
			width: 460px;
			bottom: -90px;
			left: 51.6%;
			transform: translateX(-50%);
		}
		&--left{
			width: 707px;
			top: -134px;
			right: -80px;
			@media(max-width: $md){
				right: -60px;
			}
		}
		&--right{
			width: 707px;
			top: -134px;
			left: -80px;
			@media(max-width: $md){
				left: -60px;
			}
		}
		&__arr{
			width: 60px;
			height: 60px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			z-index: 1;
			left: 50%;
			bottom: -5px;
			transform: translateX(-50%);
			background: $c_alt;
			box-shadow: 0 2px 10px rgba(0,0,0,0.3);
			cursor: pointer;
			transition: all 0.3s;
			&:active{
				bottom: 41px;
			}
			&:hover{
				background: $c_alt_dark
			}
		}
	}

	.logo{
		position: absolute;
		top: -120px;
		left: 50%;
		transform: translateX(-50%);
		&__img{
			line-height: 0;
			width: 85px;
			height: 85px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__bg{
			width: 160px;
			height: 160px;
			position: absolute;
			top: -36px;
			left: -37px;
			&.has-big{

			}
		}

		&__feather1{
			position: absolute;
			left: -76px;
			top: 0px;
			animation: 3s 0.3s ease-in infinite animaFeather1;
		}
		&__feather2{
			position: absolute;
			right: -63px;
			top: 0;
			animation: 3s 0.6s ease-in infinite animaFeather2;
		}
		&__feather3{
			position: absolute;
			right: -70px;
			top: 41px;
			animation: 3s 0.9s ease-in infinite animaFeather3;
		}
	}

	.status{
		padding: 3px 10px 2px;
		display: inline-block;
		border-radius: 5px;
		font-family: $f_alt;
		&--paid,
		&--created{
			color: $c_success;
		}
		&--ended{
			color: #000;
		}
		&--canceled,
		&--failed{
			color: $c_error;
		}
	}

	.euro{
		width: 18px;
		padding-right: 2px;
		padding-top: 1px;
		height: 18px;
		border: 1px solid #000;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
	}

	.prize{
		display: flex;
		align-items: center;
		&.has-sm{
			.prize__icon{
				width: 28px;
				height: 28px;
			}
		}
		svg{
			//fill: $c_main_light;
		}
		&--coin{
			transform: translateX(-2px);
			.prize__icon{
				width: 45px !important;
				height: 45px !important;
				padding: 0;
				&--sm{
					width: 30px !important;
					height: 30px !important;
					padding: 0;
				}
				svg{
					fill: $c_main;
					width: 100%;
					height: 100%;
				}
			}
		}
		&--achieve{
			.prize__icon{
				background: $c_bg;
				padding: 10px;
				&--sm{
					padding: 5px;
				}
			}
		}
		&--ex{
			.prize__icon{
				background: $c_bg_light;
			}
		}
		&--discount,
		&--cashback{
			.prize__icon{
				padding: 0;
				&--sm{
					width: 28px;
					height: 28px;
				}
			}
			svg{
				fill: $c_alt;
			}
		}
		&--achieveNormal,
		&--rang{
			.prize__icon{
				padding: 0;
				&--sm{
					width: 28px;
					height: 28px;
				}
			}
			svg{
				fill: $c_alt;
				height: 100%;
			}
		}
		&__icon{
			width: 40px;
			height: 40px;
			border-radius: 50%;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 0;
			flex-shrink: 0;
			&--sm{
				width: 25px;
				height: 25px;
			}
		}
		span{
			padding-left: 5px;
			font-size: 15px;
			line-height: 19px;
			font-family: $f_alt;
			white-space: nowrap;
		}
	}

	@keyframes animaFeather1{
		from{
			left: -76px;
			top: 0px;
			transform: rotate(0deg);
		}
		50% {
			left: -79px;
			top: 3px;
			transform: rotate(5deg);
		}
		to {
			left: -76px;
			top: 0px;
			transform: rotate(0deg);
		}
	}

	@keyframes animaFeather2{
		from{
			right: -63px;
			top: 0;
			transform: rotate(0deg);
		}
		50% {
			right: -66px;
			top: 3px;
			transform: rotate(10deg);
		}
		to {
			right: -63px;
			top: 0;
			transform: rotate(0deg);
		}
	}

	@keyframes animaFeather3{
		from{
			right: -70px;
			top: 41px;
			transform: rotate(0deg);
		}
		50% {
			right: -73px;
			top: 44px;
			transform: rotate(5deg);
		}
		to {
			right: -70px;
			top: 41px;
			transform: rotate(0deg);
		}
	}
</style>
