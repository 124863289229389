<template>
	<div class="chat">
		<div class="chat__main">
			<div class="chat__container" ref="chat">
				<ul class="chat__list">
					<li class="chat__item" v-for="item in list">
						<div class="chat__card" :class="{'has-you': item.sender.role === ROLE_CLIENT}">
							<div class="chat__ava">
								<img :src="require('@img/ava/ava1.png')" v-if="item.sender.role === ROLE_CLIENT" alt="">
								<img :src="require('@img/ava/ava2.png')" v-else-if="item.sender.role === ROLE_BOOSTER" alt="">
								<img :src="require('@img/faq_icon1.png')" alt="" v-else>
							</div>
							<div class="chat__text">{{item.message}}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="chat__sub">
			<textarea class="chat__area" v-model="message" placeholder="Write your message..."></textarea>
			<!--<div class="chat__file">-->
				<!--<v-icon name="paperclip"></v-icon>-->
			<!--</div>-->
			<div class="chat__send" @click="onSend">
				<v-icon name="paper-plane"></v-icon>
				<span>send</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'calc-chat',
		props:['order'],
		data(){
			return{
				message: null,
				hasLoadData: false,

				intervalTimer: null
			}
		},

		computed:{
			...mapState({
				ROLE_CLIENT: state => state.orders.chat.ROLE_CLIENT,
				ROLE_BOOSTER: state => state.orders.chat.ROLE_BOOSTER,

				list: state => state.orders.chat.list,
				loadingGetList: state => state.orders.chat.loadingGetList,
				loadingSaveItem: state => state.orders.chat.loadingSaveItem
			})
		},

		created(){
			this.getList('init');

			this.intervalTimer = setInterval(() => { //@TODO need update to socket
				this.getList();
			},10000);
		},

		beforeDestroy(){
			clearInterval(this.intervalTimer)
		},

		methods:{
			...mapActions({
				GET_LIST: 'orders/chat/GET_LIST',
				SAVE_ITEM : 'orders/chat/SAVE_ITEM'
			}),

			getList: $fn.debounce(function (type) {
				this.GET_LIST(this.order.id).then(() => {
					this.hasLoadData = true;
				});

				if(type === 'init') this.scrollChat();
			}, 300),

			onSend(){
				this.SAVE_ITEM({
					orderId: this.order.id,
					formData:{
						message: this.message
					}

				}).then(() => {
					this.message = null;
					this.scrollChat();
				})
			},

			scrollChat(){
				setTimeout(() => {
					this.$refs['chat'].scrollTop = this.$refs['chat'].scrollHeight
				},200)

			}
		}
	};
</script>

<style scoped lang=scss>
	.chat{
		height: 500px;
		width: 100%;
		border-radius: 50px;
		background: #DCD2E4;
		&__main{
			height: 400px;
			padding: 40px 10px 20px 10px;
			position: relative;
			&:before{
				content: '';
				display: block;
				height: 40px;
				position: absolute;
				left: 20px;
				right: 25px;
				top: 30px;
				background: -moz-linear-gradient(top,  rgba(220,210,228,1) 0%, rgba(220,210,228,0) 100%);
				background: -webkit-linear-gradient(top,  rgba(220,210,228,1) 0%,rgba(220,210,228,0) 100%);
				background: linear-gradient(to bottom,  rgba(220,210,228,1) 0%,rgba(220,210,228,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dcd2e4', endColorstr='#00dcd2e4',GradientType=0 );
			}
			&:after{
				content: '';
				display: block;
				height: 40px;
				position: absolute;
				left: 10px;
				right: 25px;
				bottom: 20px;
				background: -moz-linear-gradient(top,  rgba(220,210,228,0) 0%, rgba(220,210,228,1) 100%);
				background: -webkit-linear-gradient(top,  rgba(220,210,228,0) 0%,rgba(220,210,228,1) 100%);
				background: linear-gradient(to bottom,  rgba(220,210,228,0) 0%,rgba(220,210,228,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00dcd2e4', endColorstr='#dcd2e4',GradientType=0 );

			}
		}
		&__sub{
			height: 100px;
			background: #e6dcee;
			border-radius: 30px;
			position: relative;
			padding: 20px;
		}
		&__file{
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			padding: 5px;
			cursor: pointer;
			&:hover{
				svg{
					fill: $c_alt;
				}
			}
			svg{
				width: 25px;
				height: 25px;
				fill: $c_text_light;
			}
		}
		&__send{
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			border-radius: 30px;
			background: #D76700;
			padding: 5px 10px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover{
				background: #cc6600;
				box-shadow: 0 1px 8px rgba(0,0,0,0.2);
			}
			svg{
				fill: #fff;
				margin-right: 5px;
			}
			span{
				color: #fff;
				font-family: $f_alt;
				font-size: 20px;
			}
		}
		&__area{
			display: block;
			width: 100%;
			height: 100%;
			resize: none;
			padding: 18px 85px 0 30px;
			border: none;
			background: transparent;
		}
		&__container{
			height: 100%;
			width: 100%;
			overflow: auto;
			border-radius: 5px;
			padding: 20px 0;
			display: flex;
			align-items: flex-end;
			&::-webkit-scrollbar {
				@media(min-width: $def) {
					width: 12px;
					border-radius: 5px;
					background-color: #F5F5F5;
				}
			}

			&::-webkit-scrollbar-track {
				@media(min-width: $def) {
					-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
					border-radius: 5px;
					background-color: #F5F5F5;
				}
			}

			&::-webkit-scrollbar-thumb {
				@media(min-width: $def) {
					border-radius: 5px;
					-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
					background-color: #9f9f9f;
				}
			}
		}
		&__item{
			margin-bottom: 20px;
		}
		&__card{
			width: 70%;
			display: flex;
			align-items: flex-end;
			&.has-you{
				margin-left: auto;
				justify-content: flex-end;
				.chat__text{
					order: -1;
					background: rgba(238, 228, 246, 0.62);
					border-radius: 50px 50px 0 50px;
				}
				.chat__ava{
					margin-right: 0;
					margin-left: 10px;
				}
			}
		}
		&__ava{
			width: 40px;
			margin-right: 10px;
			flex-shrink: 0;
			line-height: 0;
		}
		&__text{
			padding: 20px;
			border-radius: 50px 50px 50px 0;
			background: #f3e9fb;
		}
	}
</style>
