<template>
	<div class="section">
		<div class="container" v-if="hasLoadData">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table" id="formBlock">
						<div class="title title--section">Order {{ORDER_PRE_KEY}}{{id}}</div>

						<div class="section__card">
							<table class="table">
								<tr v-if="!accessBooster">
									<td>
										<b>Client:</b>
									</td>
									<td>
										<div>{{order.email}}</div>
										 <div v-if="order.client && order.client.skype">{{order.client.skype}}</div>
									</td>
								</tr>
								<tr>
									<td>
										<b>Game / Service:</b>
									</td>
									<td>
										<span>{{order.game.title}} => {{order.service.translations.title[selectedLang]}}</span>
									</td>
								</tr>
								<tr v-if="order.params.comment && order.params.comment.value">
									<td><b>Comment</b></td>
									<td><span>{{order.params.comment.value}}</span></td>
								</tr>
								<tr v-if="order.params.account_login && order.params.account_login.value">
									<td><b>Account login</b></td>
									<td><span>{{order.params.account_login.value}}</span></td>
								</tr>
								<tr v-if="order.params.account_password && order.params.account_password.value">
									<td><b>Account password</b></td>
									<td><span>{{order.params.account_password.value}}</span></td>
								</tr>
							</table>
						</div>
					</div>

					<div class="section__main">

						<div class="conf">
							<div class="conf__progress" v-if="hasLoadData">
								<calc-progress :order="order"></calc-progress>
							</div>

							<div class="container container--sm">
								<div class="conf__navWrap">
									<div class="container">
										<div class="conf__nav">
											<div class="conf__sub">
												<div class="conf__options">
													<div class="conf__option">
														<div class="conf__opText">
															<v-icon name="info-circle"></v-icon>
															<span>Booster</span>
														</div>
														<div class="conf__inf">
															<template v-if="order.booster">
																<div class="boost">
																	<font-awesome-icon icon="fa-solid fa-user"/>
																	<span>{{order.booster.name}}</span>
																	<div class="boost__edit" @click="onAddBoosterToOrder" v-if="!accessBooster">
																		<font-awesome-icon icon="fa-regular fa-pen-to-square"/>
																	</div>
																</div>
															</template>

															<div class="link" v-else @click="onAddBoosterToOrder">
																<font-awesome-icon icon="fa-regular fa-plus-circle"/>
																<span>Selected booster</span>
															</div>
														</div>
													</div>
													<div class="conf__option">
														<div class="conf__opText">
															<v-icon name="info-circle"></v-icon>
															<span>Order's sum</span>
														</div>
														<div class="conf__inf">{{order.price}}$</div>
													</div>
													<div class="conf__option">
														<div class="conf__opText">
															<v-icon name="info-circle"></v-icon>
															<span>Date of completion of the order</span>
														</div>
														<div class="conf__inf"><i>Create:</i>{{order.deadline_at | dateNormalize}}</div>
														<div class="conf__inf"><i>Deadline:</i>{{order.deadline_at | dateNormalize}}</div>
													</div>
													<div class="conf__option" v-if="order.status !== ORDER_STATUS_ENDED">
														<div class="conf__opText">
															<v-icon name="info-circle"></v-icon>
															<span>Complete the order when successful</span>
														</div>

														<alert-modal @success="onChangeStatus" :class="{'has-disabled' : loadingSaveItem}">
															<button class="btn has-width">Complete</button>
														</alert-modal>
													</div>
												</div>
											</div>

											<div class="conf__chat">
												<calc-chat :order="order"></calc-chat>
											</div>
										</div>

										<div class="conf__br">
											<img :src="require('@img/pageCabinet/progress_bar.webp')" alt="">
										</div>
									</div>
								</div>
							</div>

							<div class="conf__main">
								<div class="conf__layout" v-if="hasLoadData && hasInitCommonData">
									<calc-layout :order="order" :orderPrizes="profits"></calc-layout>
								</div>
							</div>

							<div class="conf__info">
								<div class="container container--sm">
									<div class="conf__btn">
										<router-link :to="`/orders`" class="btn btn--secong">
											<v-icon name="arrow-left"></v-icon>
											<span>Back to orders</span>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import CalcChat from './module/CalcChat';
	import CalcLayout from './module/CalcLayout';
	import CalcProgress from './module/CalcProgress';

	import ModalAddBooster from '@views/orders/components/ModalAddBooster';

	export default {
		name: 'order-item',
		components:{
			CalcChat,
			CalcLayout,
			CalcProgress,
		},
		props:['id'],
		data(){
			return {
				selectedLang : this.$store.state.selectedLang,

				profitsList: [],

				hasLoadData: false,
				hasInitCommonData: false
			}
		},

		computed:{
			...mapState({
				ORDER_PRE_KEY: state => state.orders.ORDER_PRE_KEY,
				ORDER_STATUS_ENDED: state => state.orders.ORDER_STATUS_ENDED,
				order: state => state.orders.item,
				profits: state => state.orders.profits,
				statuses: state => state.orders.statuses,
				loadingSaveItem: state => state.orders.loadingSaveItem,
			}),
			...mapGetters({
				accessBooster: 'accessBooster'
			}),
		},

		created(){
			this.initCommonData().then(() => {
				this.hasInitCommonData = true;
			});

			if(!this.accessBooster) this.GET_BOOSTERS();

			this.getOrder();
		},

		methods:{
			...mapActions({
				GET_ORDER: 'orders/GET_ITEM',
				SAVE_ITEM: 'orders/SAVE_ITEM',
				GET_CURRENCIES: 'GET_CURRENCIES',
				GET_SETTINGS: 'GET_SETTINGS',
				GET_BOOSTERS: 'orders/GET_BOOSTERS',
				GET_ORDER_PROFIT : 'orders/GET_ORDER_PROFIT'
			}),

			getOrder(){
				return this.GET_ORDER(this.id).then(() => {
					this.hasLoadData = true;
				})
			},

			onChangeStatus(){
				this.SAVE_ITEM({
					id: this.id,
					status: this.ORDER_STATUS_ENDED,
					booster_id: !this.accessBooster
						? this.order.booster ? this.order.booster.id : null
						: null,

				}).then(() => {
					this.getOrder().then(() => {
						this.$bus.$emit('event-end-proggress');
					});
				})
			},

			onAddBoosterToOrder(){
				this.$modal.show(ModalAddBooster,{
					order: this.order
				}, $MODAL_OPTIONS.default);
			},

			initCommonData () {
				return Promise.all([
					this.GET_ORDER_PROFIT(this.id).then(resp => {
						this.profitsList = resp.list;
					})
				])
			},

		}
	};
</script>

<style scoped lang=scss>
	.title{
		font-weight: normal;
	}
	.section{
		padding: 20px 0;
		margin-top: -20px;
		overflow: hidden;
		.container{
			padding: 0 30px;
		}
	}

	.conf{
		&__main{
			padding: 50px 0;
			min-height: 790px;
		}
		&__layout{
			max-width: 1180px;
			width: 100%;
			margin: 0 auto;
		}
		&__info{
			padding-bottom: 70px;

		}
		&__container{
			padding: 80px 20px 70px;
			margin-bottom: 30px;
			position: relative;
		}
		&__text{
			max-width: 740px ;
			width: 100%;
			margin: 0 auto;
			text-align: center;
			font-size: 18px;
			line-height: 25px;
			font-family: $f_alt;
		}
		&__btn{
			display: flex;
			justify-content: center;
		}
		&__nav{
			display: flex;
			flex-wrap: wrap;
		}
		&__sub{
			width: 40%;
		}
		&__chat{
			width: 60%;
		}
		&__inf{
			padding-left: 25px;
			font-family: $f_alt;
			color: $c_alt;
			font-size: 20px;
			i{
				color: $c_text_light;
				font-size: 14px;
				min-width: 70px;
				display: inline-block;
			}
		}
		&__options{
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			max-width: 370px;
			width: 100%;
			.btn{
				margin-left: 20px;
			}
		}
		&__option{
			margin-bottom: 60px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		&__opText{
			font-size: 12px;
			line-height: 1.3em;
			margin-bottom: 10px;
			font-weight: 600;
			text-align: left;
			display: flex;
			text-transform: uppercase;
			svg{
				margin-right: 5px;
				flex-shrink: 0;
			}
		}
		&__br{
			text-align: center;
			padding: 80px 0 0;
		}
	}

	.ico{
		position: absolute;
		&--type1{
			left: 3px;
			top: 3px;
		}
		&--type2{
			right: 3px;
			top: 3px;
			transform: rotate(90deg);
		}
		&--type3{
			left: 3px;
			bottom: 3px;
			transform: rotate(-90deg);
		}
		&--type4{
			right: 3px;
			bottom: 3px;
			transform: rotate(180deg);
		}
		&__wrap{
			&--top{
				&:before{
					content: '';
					display: block;
					width: 1px;
					background: rgba(23,16,38, 0.3);
					top: 35px;
					bottom: 35px;
					left: 4px;
					position: absolute;
				}
				&:after{
					content: '';
					display: block;
					width: 1px;
					background: rgba(23,16,38, 0.3);
					top: 35px;
					bottom: 35px;
					right: 4px;
					position: absolute;
				}
			}
			&--bot{
				&:before{
					content: '';
					display: block;
					height: 1px;
					background: rgba(23,16,38, 0.3);
					top: 4px;
					left: 35px;
					right: 35px;
					position: absolute;
				}
				&:after{
					content: '';
					display: block;
					height: 1px;
					background: rgba(23,16,38, 0.3);
					bottom: 5px;
					left: 35px;
					right: 35px;
					position: absolute;
				}
			}
		}
	}

	.boost{
		cursor: pointer;
		display: flex;
		align-items: center;
		&:hover{
			span{
				text-decoration: none;
			}
		}
		svg{
			margin-right: 5px;
		}
		span{
			text-decoration: underline;
		}
		&__edit{
			color: $c_text_light;
			margin-left: 5px;
			cursor: pointer;
			display: block;
			position: relative;
			z-index: 1;
			&:hover{
				color: $c_alt;
			}
			svg{
				width: 15px;
				height: 15px;
			}
		}
	}
</style>
