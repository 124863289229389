<template>
	<div class="prog">
		<div class="container">
			<div class="prog__status">
				<div class="prog__statLabel">
					<span>order status</span>
					<div class="order__status">
						<div :class="`status status--${order.status}`" :style="{'background-image': `url(/img/pattern1_alt.webp)`}">
							{{order.status}}
						</div>
					</div>
				</div>
			</div>
			<div class="prog__title">
				<b>Order progress: <i>{{(progressPersent || 0) | numberPrice}}%</i></b>
			</div>
			<div class="prog__line">
				<span :style="{'width': `${progressPersent}%`}"></span>
			</div>

			<div class="prog__slider" v-if="progressType === PROGRESS_TYPE_SINGLE ||
											progressType === PROGRESS_TYPE_SLIDER ||
											progressType === PROGRESS_TYPE_RANGES"
					:class="{'has-disabled': order.status === ORDER_STATUS_CANCELED
											|| order.status === ORDER_STATUS_ENDED
											|| order.status === ORDER_STATUS_FAILED}">
				<vue-slider v-model="activeCount"
						tooltip="none"
						@drag-end="onChangeRange($event)"
						:min="sliderMinRange"
						:max="sliderMaxRange">
				</vue-slider>
			</div>

			<div class="steps__wrap">
				<div class="steps" v-if="hasLoadData">
					<ul class="steps__list" :class="{'has-disabled': order.status === ORDER_STATUS_CANCELED
																	|| order.status === ORDER_STATUS_ENDED
																	|| order.status === ORDER_STATUS_FAILED}">
						<template v-if="progressType === PROGRESS_TYPE_STEPS || progressType === PROGRESS_TYPE_SLIDER">
							<li class="steps__item" v-for="(item, index) in formattedServiceRanges">
								<div class="steps__card"
										:class="{'has-end': index <= activeIndex,
												'has-active': index === activeIndex,
												'has-label': progressType === PROGRESS_TYPE_SLIDER}"
										@click="onSelect(item)">
									<div class="steps__img">
										<img :src="item.img" alt="">
									</div>
									<div class="steps__label">
										{{item.lvlText[selectedLang]}}
										<template v-if="item.divisionKey">{{item.divisionText[selectedLang]}}</template>
									</div>
									<div class="steps__active" :class="{'has-end': item < activeIndex}" v-if="index === activeIndex">
										Active
									</div>
								</div>
							</li>
						</template>

						<template v-if="progressType === PROGRESS_TYPE_SINGLE || progressType === PROGRESS_TYPE_SLIDER">
							<li class="steps__item">
								<div class="steps__label">start</div>
								<div class="steps__num">{{minRange}}</div>
								<div class="steps__countText">{{serviceProgressCnf.labelText[selectedLang]}}</div>
							</li>
							<li class="steps__item steps__item--activeCount">
								<div class="steps__num steps__num--active">{{activeCount}}</div>
								<div class="steps__label steps__label--active">current <br> position</div>
							</li>
							<li class="steps__item">
								<div class="steps__label">end</div>
								<div class="steps__num">{{order.params.count.value}}</div>
								<div class="steps__countText">{{serviceProgressCnf.labelText[selectedLang]}}</div>
							</li>
						</template>

						<template v-if="progressType === PROGRESS_TYPE_RANGES">
							<div class="steps__item">
								<div class="steps__card has-label">
									<div class="steps__label">start</div>
									<div class="steps__img">
										<img :src="formattedRangesCnf.currentImg" alt="">
									</div>
									<div class="steps__label">{{formattedRangesCnf.currentValue}} {{serviceProgressCnf.labelText[selectedLang]}}</div>
								</div>
							</div>

							<li class="steps__item steps__item--activeCount">
								<div class="steps__card  has-label">
									<div class="steps__num steps__num--active">{{activeCount}}</div>
									<div class="steps__label steps__label--active">current <br> position</div>
								</div>
							</li>

							<div class="steps__item">
								<div class="steps__card  has-label">
									<div class="steps__label">end</div>
									<div class="steps__img">
										<img :src="formattedRangesCnf.desiredImg" alt="">
									</div>
									<div class="steps__label">{{formattedRangesCnf.desiredValue}} {{serviceProgressCnf.labelText[selectedLang]}}</div>
								</div>
							</div>
						</template>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';
	import vueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/antd.css'

	export default {
		name: 'calc-progress',
		components: {
			vueSlider
		},
		props:['order'],
		data(){
			return{
				activeKey: null,
				activeCount: 0,

				minRange: 1,

				hasLoadData: false
			}
		},

		computed:{
			...mapState({
				ORDER_STATUS_CANCELED: state => state.orders.ORDER_STATUS_CANCELED,
				ORDER_STATUS_ENDED: state => state.orders.ORDER_STATUS_ENDED,
				ORDER_STATUS_FAILED: state => state.orders.ORDER_STATUS_FAILED,
				PROGRESS_TYPE_STEPS: state => state.orders.progress.PROGRESS_TYPE_STEPS,
				PROGRESS_TYPE_RANGES: state => state.orders.progress.PROGRESS_TYPE_RANGES,
				PROGRESS_TYPE_SINGLE: state => state.orders.progress.PROGRESS_TYPE_SINGLE,
				PROGRESS_TYPE_SLIDER: state => state.orders.progress.PROGRESS_TYPE_SLIDER,

				serviceRanges: state => state.orders.progress.serviceRanges,
				progressType: state => state.orders.progress.progressType,
				serviceProgressCnf: state => state.orders.progress.serviceProgressCnf,
				selectedLang:state=> state.selectedLang,
			}),
			...mapGetters({
				accessBooster: 'accessBooster'
			}),

			progressPersent(){
				switch (this.progressType){
					case this.PROGRESS_TYPE_STEPS:
						return (this.activeIndex + 1) * 100 / this.formattedServiceRanges.length;

					case this.PROGRESS_TYPE_SINGLE:
					case this.PROGRESS_TYPE_SLIDER:
					case this.PROGRESS_TYPE_RANGES:
						return (this.activeCount - this.sliderMinRange) * 100  / (this.sliderMaxRange - this.sliderMinRange) ;

				}

			},

			activeIndex(){
				return $fn.findIndex(this.formattedServiceRanges, ['key', this.activeKey])
			},

			formattedServiceRanges(){
				let currentKey = '',
					desiredKey = '';

				currentKey += this.order.params.current_level
								? this.order.params.current_level.key
								: this.order.params.level ?  this.order.params.level.key : '';
				currentKey += this.order.params.current_division
								? `_${this.order.params.current_division.key}`
								: this.order.params.division ? `_${this.order.params.division.key}` : '';

				let startIndex = $fn.findIndex(this.serviceRanges, ['key', currentKey]);

				if(this.order.params.desired_level){
					desiredKey += this.order.params.desired_level.key;
					desiredKey += this.order.params.desired_division ? `_${this.order.params.desired_division.key}` : '';

					let endIndex = $fn.findIndex(this.serviceRanges, ['key', desiredKey]);
					return this.serviceRanges.slice(startIndex, endIndex + 1);

				} else{
					return [this.serviceRanges[startIndex]];
				}
			},

			formattedRangesCnf(){
				let list = [];

				let current =  this.order.params.current,
					desired =  this.order.params.desired;

				return {
					currentImg :  current && current.additional ? $fn.find(current.additional, ['attribute', 'image']).value : null,
					currentValue : current ?  current.value : null,
					desiredImg : desired && desired.additional ? $fn.find(desired.additional, ['attribute', 'image']).value : null,
					desiredValue : desired ? desired.value : null,
				};
			},

			sliderMinRange(){
				return (this.formattedRangesCnf && this.formattedRangesCnf.currentValue)
					? +this.formattedRangesCnf.currentValue
					: this.minRange - 1
			},

			sliderMaxRange(){
				return (this.formattedRangesCnf && this.formattedRangesCnf.desiredValue)
					? +this.formattedRangesCnf.desiredValue
					: this.order.params.count
						? +this.order.params.count.value
						: 0
			}
		},

		created(){
			this.RESET_PROGRESS_STORE();

			if(this.order.progress) {
				this.activeKey = this.order.progress.activeKey;
				this.activeCount = this.order.progress.activeCount;

			} else {
				this.activeCount =  this.sliderMinRange;
			}

			this.getServiceRanges()	;

			this.$bus.$on('event-end-proggress', () => {
				let item = this.formattedServiceRanges[this.formattedServiceRanges.length - 1];
				this.activeKey = item ? item.key : null;

				this.activeCount =  this.sliderMaxRange;

				this.saveOrder();
			});
		},

		methods:{
			...mapMutations({
				RESET_PROGRESS_STORE: 'orders/progress/RESET_STORE'
			}),
			...mapActions({
				GET_SERVICE_RANGES: 'orders/progress/GET_SERVICE_RANGES',
				SAVE_ITEM: 'orders/SAVE_ITEM'
			}),

			getServiceRanges(){
				this.GET_SERVICE_RANGES(this.order.service.id).then(() => {
					this.hasLoadData = true;
				})
			},

			onChangeRange(){
				this.saveOrder();
			},

			onSelect(item){
				this.activeKey = item.key;
				this.saveOrder();
			},

			saveOrder: $fn.debounce(function () {
				this.SAVE_ITEM({
					id: this.order.id,
					status: this.order.status,
					booster_id: !this.accessBooster
						? this.order.booster ? this.order.booster.id : null
						: null,
					progress: {
						activeKey: this.activeKey,
						activeCount: this.activeCount
					}
				})
			}, 300),
		}
	};
</script>

<style scoped lang=scss>
	.prog{
		padding: 20px 0 50px;
		&__title{
			display: flex;
			justify-content: space-between;
			b{
				font-family: $f_alt;
				font-weight: normal;
				i{
					color: $c_alt;
					font-style: normal;
					font-size: 24px;
				}
			}
			span{
				color: $c_error;
				font-size: 12px;
				display: flex;
				align-items: center;
				svg{
					fill: $c_error;
					margin-right: 5px;
					width: 17px;
					height: 17px;
				}
			}
		}
		&__line{
			height: 20px;
			width: 100%;
			background: #E6E6E6;
			margin-bottom: 20px;
			position: relative;
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.05) 25%, transparent 0, transparent 50%, rgba(0, 0, 0, 0.05) 0, rgba(0, 0, 0, 0.05) 75%, transparent 0, transparent);
				z-index: 1;
				background-size: 50px 50px;
				overflow: hidden;
				border-radius: 0 25px 0 25px;
			}
			span{
				position: absolute;
				z-index: 2;
				left: 0;
				top: 0;
				height: 100%;
				background: $c_alt;
				border-radius: 0 5px 5px 0;
				transition: all 1s;
			}
		}
		&__status{
			margin-left: auto;
			max-width: 200px;
			width: 100%;
			display: flex;
			align-items: center;
			margin-bottom: 10px;
		}
		&__statLabel{
			text-align: center;
			font-family: $f_alt;
			line-height: 1.3em;
			&.has-warning{
				b{
					color: $c_warning;
				}
			}
			span{
				display: block;
				color: $c_text_light;
			}
			b{
				font-weight: normal;
				font-size: 26px;
			}

		}
		&__slider{
			max-width: 500px;
			margin: 0 auto;
		}
	}

	.steps{
		overflow-x: auto;
		max-width: 100%;
		&::-webkit-scrollbar {
			height: 12px;
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px $c_main_light_3;
			border-radius: 5px;
			background-color: #F5F5F5;
		}
		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			-webkit-box-shadow: inset 0 0 6px $c_main_light_3;
			background-color: #9f9f9f;
		}
		&__wrap{
			display: flex;
			justify-content: center;
		}
		&__list{
			display: flex;
			&.has-disabled{
				opacity: 1;
			}
		}
		&__item{
			flex-grow: 1;
			min-width: 120px;
			padding: 0 10px;
			&--activeCount{
				min-width: 140px;
			}
		}
		&__card{
			filter: grayscale(1);
			padding: 5px;
			opacity: 0.8;
			cursor: pointer;
			transition: all 0.3s;
			&.has-label,
			&.has-end{
				filter: grayscale(0);
				opacity: 1;
				.steps__label{
					color: $c_text;
				}
				.steps__active{
					opacity: 1;
					visibility: visible;
				}
			}
			&.has-label{
				pointer-events: none;
			}
			&.has-active{
				.steps__label{
					color: $c_alt;
				}
				.steps__active{
					opacity: 1;
					visibility: visible;
				}
			}
			&:hover{
				filter: grayscale(0);
				opacity: 1;
				.steps__label{
					color: $c_alt;
				}
			}
		}
		&__img{
			text-align: center;
			line-height: 0;
			height: 120px;
			img{
				max-height: 100%;
			}
		}
		&__label{
			font-family: $f_alt;
			text-align: center;
			color: $c_text_light;
			transition: all 0.3s;
			line-height: 1.2em;
			&--active{
				color: $c_alt;
			}
		}
		&__active{
			opacity: 0;
			visibility: hidden;
			text-align: center;
			font-family: $f_alt;
			color: $c_success;
			transition: all 0.3s;
			&.has-end{
				color: $c_text_light;
			}
		}

		&__num{
			font-size: 72px;
			line-height: 1em;
			font-family: $f_alt;
			text-align: center;
			&--active{
				font-size: 90px;
				color: $c_alt;
			}
		}
		&__countText{
			text-align: center;
			font-family: $f_alt;
		}
	}

	.status{
		padding: 3px 10px 2px;
		display: inline-block;
		border-radius: 5px;
		font-family: $f_alt;
		font-size: 26px;
		&--paid,
		&--created{
			color: $c_success;
		}
		&--ended{
			color: #000;
		}
		&--canceled,
		&--failed{
			color: $c_error;
		}
	}
</style>
