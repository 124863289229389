import { render, staticRenderFns } from "./CalcChat.vue?vue&type=template&id=dbcc17b4&scoped=true&"
import script from "./CalcChat.vue?vue&type=script&lang=js&"
export * from "./CalcChat.vue?vue&type=script&lang=js&"
import style0 from "./CalcChat.vue?vue&type=style&index=0&id=dbcc17b4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbcc17b4",
  null
  
)

export default component.exports